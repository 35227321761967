body {
  font-family: 'Bebas Neue', sans-serif;
  line-height: 1.6;
  color: #333;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #f8f9fa;
  padding: 20px;
  position: relative;
}

.header-content {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.header-image {
  width: 100px;
  height: 100px;
  border: 3px solid #007bff;
  border-radius: 50%;
  padding: 5px;
  margin-right: 20px;
}

.header-text {
  text-align: center;
}

.header-text h1 {
  margin: 0;
  font-size: 2.5em;
  color: #007bff;
}

.header-text p {
  margin: 5px 0;
  font-size: 1.2em;
}

.quote-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quote-button:hover {
  background-color: #0056b3;
}

main {
  padding: 20px;
}

section {
  margin-bottom: 40px;
}

h2 {
  color: #007bff;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

.carousel-section {
  max-width: 800px;
  margin: 0 auto;
}

.carousel-container {
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
}

.slick-slide img {
  width: 100%;
  height: auto;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

footer {
  background-color: #f8f9fa;
  padding: 20px;
  margin-top: 40px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.popup h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.popup form {
  display: flex;
  flex-direction: column;
}

.popup input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.popup button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup button[type="submit"] {
  background-color: #007bff;
  color: white;
}

.popup button[type="button"] {
  background-color: #f8f9fa;
  color: #333;
}

.popup textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 100px;
  resize: vertical;
}